import {
  isNil,
} from "lodash-es";

export function changeLinkToRoute(router) {
  document.addEventListener("click", event => {
    let target = event.target;
    while (target && target !== document) {
      if (target.matches("a")) {
        const href = target.getAttribute("href");
        const targetAttribute = target.getAttribute("target");
        if (isNil(href) ||
          href === "#" ||
          event.ctrlKey ||
          event.metaKey ||
          targetAttribute === "_blank") {
          return;
        }

        const resolvedRoute = router.resolve(href);
        if (resolvedRoute.name) {
          event.preventDefault();
          router.push(href);
        }
        return;
      }
      target = target.parentNode;
    }
  });
}
