import AButton from "aloha-vue/src/AButton/AButton";
import ASpinner from "aloha-vue/src/ASpinner/ASpinner";
import Modal from "../../global/components/Modal/Modal.vue";
import Permission from "../../global/components/Permission/Permission.vue";

import translate from "../../global/directives/translate";

import AHttpAPI from "aloha-vue/src/compositionAPI/AHttpAPI";
import NotificationAPI from "../../global/compositionAPI/NotificationAPI";
import {
  toggleTranslate,
  updateTranslation,
} from "aloha-vue/src/ATranslation/compositionAPI/ATranslationAPI";
import TranslateAPI from "../../App/compositionAPI/TranslateAPI";

import {
  gettext,
} from "../../global/functions/utils";
import EventBus from "aloha-vue/src/utils/EventBus";
import {
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "TheModalTranslate",
  components: {
    AButton,
    ASpinner,
    Modal,
    Permission,
  },
  directives: {
    translate,
  },
  setup() {
    const {
      getListHttp,
      postHttp,
      putHttp,
    } = AHttpAPI();

    const {
      addNotification,
    } = NotificationAPI();

    const {
      loadTranslate
    } = TranslateAPI();

    return {
      addNotification,
      getListHttp,
      loadTranslate,
      postHttp,
      putHttp,
    };
  },
  data() {
    return {
      btnId: "btn_modal_translate",
      model: {},
      options: {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "text",
            type: "text",
            label: "Placeholder",
            required: true,
            view: "v",
            disabled: true,
          },
          {
            id: "translate",
            type: "textarea",
            label: "Übersetzung",
            required: true,
            view: "v",
          },
        ],
      },
      status: {
        loading: undefined,
        modal: undefined,
        show: undefined,
        spinnerLoading: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
      dataForUbsText: [],
      objSpracheText: {},
    };
  },
  computed: {
    optionsModal() {
      return {
        required: true,
        showCloseButton: true,
        list: [
          {
            id: "ubs_sprache",
            type: "select",
            label: "Sprache",
            view: "v",
            required: true,
            keyLabel: "label",
            keyId: "value",
            data: [
              {
                value: "de-de",
                label: "Deutsch",
              },
            ],
            change: this.changeSprache,
            disabled: true,
          },
          {
            id: "ubs_text",
            type: "select",
            label: "Text",
            required: true,
            view: "v",
            keyId: "value",
            keyLabel: "label",
            data: this.dataForUbsText,
            disabled: this.dataForUbsText.length < 2,
          },
          {
            id: "ubs_uebersetzung",
            type: "textarea",
            label: "Übersetzung",
            required: true,
            view: "v",
          },
        ],
      };
    },

    titleForButton() {
      return this.status.show ? "Placeholders ausblenden" : "Placeholders einblenden";
    },
  },
  created() {
    this.initEventBus();
  },
  beforeUnmount() {
    EventBus.$off("setTranslate", this.openModal);
  },
  methods: {
    showPlaceholdersTranslate() {
      toggleTranslate(true);
      updateTranslation();
      setTimeout(() => {
        window.statusShowPlaceholdersTranslate = !window.statusShowPlaceholdersTranslate;
        this.status.show = window.statusShowPlaceholdersTranslate;
        EventBus.$emit("showPlaceholdersTranslate", ({ statusShow: window.statusShowPlaceholdersTranslate }));
      });
    },

    initEventBus() {
      EventBus.$on("setTranslate", this.openModal);
    },

    openModal({ value }) {
      this.status.loading = true;
      this.setDataForUbsText({ value });
      this.setModel({ value });
      this.status.modal = true;
      this.loadingData();
    },

    setDataForUbsText({ value }) {
      const DATA_FOR_UBS_TEXT = [];
      const TEXT_TEMP_OBJ = {};
      const TEXT = value.text;
      const HTML = value.html;
      const TITLE = value.title;
      if (TEXT) {
        TEXT_TEMP_OBJ[TEXT] = true;
        DATA_FOR_UBS_TEXT.push({
          value: TEXT,
          label: `text: ${ TEXT }`,
        });
      }
      if (HTML && !TEXT_TEMP_OBJ[HTML]) {
        TEXT_TEMP_OBJ[HTML] = true;
        DATA_FOR_UBS_TEXT.push({
          value: HTML,
          label: `html: ${ HTML }`,
        });
      }
      if (TITLE && !TEXT_TEMP_OBJ[TITLE]) {
        TEXT_TEMP_OBJ[TITLE] = true;
        DATA_FOR_UBS_TEXT.push({
          value: TITLE,
          label: `title: ${ TITLE }`,
        });
      }
      this.dataForUbsText = DATA_FOR_UBS_TEXT;
    },

    setModel({ value }) {
      const TEXT = value.text || value.html || value.title;
      this.model = {
        ubs_sprache: "de-de",
        ubs_text: TEXT,
        ubs_uebersetzung: gettext(TEXT),
      };
    },

    loadingData() {
      this.objSpracheText = {};
      Promise.all(this.dataForUbsText.map(ubsText => {
        return this.getListHttp({ url: `katalogadmin/uebersetzungen/eintraege/?limit=10&suche=${ ubsText.value }` });
      })).then(
        responses => {
          this.setObjSpracheText({ responses });
          this.status.loading = false;
        }
      );
    },

    setObjSpracheText({ responses }) {
      const OBJ_SPRACHE_TEXT = {};
      forEach(responses, response => {
        forEach(response, item => {
          if (!OBJ_SPRACHE_TEXT[item.ubs_sprache]) {
            OBJ_SPRACHE_TEXT[item.ubs_sprache] = {};
          }
          OBJ_SPRACHE_TEXT[item.ubs_sprache][item.ubs_text] = item;
        });
      });
      this.objSpracheText = OBJ_SPRACHE_TEXT;
    },

    save() {
      if (this.objSpracheText[this.model.ubs_sprache] && this.objSpracheText[this.model.ubs_sprache][this.model.ubs_text]) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      this.status.loading = true;
      this.postHttp({ url: "katalogadmin/uebersetzungen/eintraege/", data: this.model }).then(
        () => {
          this.loadTranslate();
          this.addNotification({ text: "Änderungen erfolgreich gespeichert" });
          this.close();
        },
        error => {
          this.errors = error.data;
          this.errorsClone = error.data;
        }
      ).then(
        () => this.status.loading = false
      );
    },

    update() {
      this.status.loading = true;
      this.putHttp({
        url: `katalogadmin/uebersetzungen/eintraege/${ this.objSpracheText[this.model.ubs_sprache][this.model.ubs_text].pk }/`,
        data: this.model
      }).then(
        () => {
          this.loadTranslate();
          this.addNotification({ text: "Änderungen erfolgreich gespeichert" });
          this.close();
        },
        error => {
          this.errors = error.data;
          this.errorsClone = error.data;
        }
      ).then(
        () => this.status.loading = false
      );
    },

    close() {
      this.status.modal = false;
    },

    changeSprache() {

    },
  },
};
